module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NodeSchool Vancouver","short_name":"nodeschool","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"node_modules/gatsby-theme-nodeschool/src/images/nodeschool-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2c6e1baef40cf2becd0eebcc599fa239"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"translations","defaultLanguage":"en","fallbackLanguage":"en","languages":["en"],"redirect":false,"siteUrl":"https://nodeschool.io/vancouver/","trailingSlash":"always","i18nextOptions":{"lng":"en","fallbackLanguage":"en","debug":false,"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i","p","code"],"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-nodeschool/gatsby-browser.js'),
      options: {"plugins":[],"title":"NodeSchool Vancouver","description":"Website for NodeSchool Vancouver.","twitter":"NodeSchoolYVR","github":"nodeschool/vancouver","url":"https://nodeschool.io/vancouver/","slack":"","meetupGroup":"nodeschool-vancouver","email":"organizers@nodeschoolyvr.com","mailchimpSubscribeUrl":"https://nodeschoolyvr.us20.list-manage.com/subscribe/post?u=703e823487e1f52bfbe0cc32d&amp;id=34f1146956","credits":{"logo":{"name":"Kenneth Ormandy","url":"https://kennethormandy.com/"}}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
